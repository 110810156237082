.Hobbies-container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    padding: 2%;
    background-color: #f0f0f0;
  }

  .Hobbies-header-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 5%;
    padding-bottom: 2%;
  }

  .Hobbies-header-text {
    font-size: calc(18px + 1vmin);
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-decoration: none;
    color: black;
    cursor: pointer;
  }

  .Hobbies-content {
    display: grid;
    grid-template-columns: 1fr;
    margin: 2%;
    padding-bottom: 5%;
  }
  
  .Hobbies-content-item {
    display: flex;
    padding: calc(20px + 1vmin);
    align-items: center;
  }
  
  .Hobbies-content-image {
    width: calc(200px + 10vmin);
    height: auto;
    margin-right: calc(100px + 2vmin);
    border-radius: 2%;
    box-shadow: calc(5px + 0.5vmin) calc(4px + 0.5vmin) calc(8px + 0.5vmin) rgba(0, 0, 0, 0.2);
  }
  
  .Hobbies-content-text {
    font-size: calc(10px + 1vmin);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  @media (max-width: 768px) {
    .Hobbies-content-item {
        flex-direction: column;
        text-align: center;
    }
    
    .Hobbies-content-image {
        margin-right: 0;
        margin-bottom: calc(20px + 1vmin);
    }
    
    .Hobbies-content-text {
        font-size: calc(12px + 1vmin);
    }
  }