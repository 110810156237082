 .Navbar-container {
    display: flex;
    justify-content: space-around;
    padding: calc(5px + 1vmin);
 }

 .Navbar-button {
    background: transparent;
    border: none;
    color: white;
    padding: calc(5px + 1vmin) calc(8px + 1vmin);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(12px + 0.5vmin);
    font-weight: bold;
    margin: calc(4px + 0.5vmin) calc(2px + 0.5vmin);
    cursor: pointer;
    border-radius: calc(8px + 1vmin);
    transition: all 0.3s ease 0s;
  }
  
  .Navbar-button:hover {
    box-shadow: 0 calc(8px + 1vmin) calc(16px + 2vmin) rgba(0, 0, 0, 0.3);
    transform: translateY(calc(-2px - 0.2vmin));
  }